<template>
  <div class="rounded-lg border bg-white p-4 pt-3 shadow-sm">
    <fieldset>
      <legend class="border-0 pt-0">Tarieven</legend>
      <div>
        <label class="col-span-full">
          <span>Groep</span>
          <select v-model="groupId">
            <option value="">Kies een groep</option>
            <optgroup label="Groepen">
              <option
                v-for="group in authorizedGroups"
                :key="group.id"
                :value="group.id"
              >
                {{ group.name }}
              </option>
            </optgroup>
          </select>
        </label>
      </div>

      <div v-if="Object.keys(tariffs).length">
        <label
          v-for="role in normalizedTariffs"
          :key="`tariff-${role.id}`"
          class="col-span-full"
        >
          <span>{{ role.title }}</span>
          <div class="relative grid grid-cols-10 items-center">
            <span class="absolute left-3">&euro;</span>
            <input
              v-model.number="tariffs[role.id]"
              type="number"
              min="0"
              class="col-span-6 pl-8"
            />
            <span class="col-span-2 text-nowrap text-center"
              >x {{ role.count }}</span
            >
            <span class="col-span-2 text-nowrap text-right"
              >&euro; {{ role.count * tariffs[role.id] }},00</span
            >
          </div>
        </label>

        <label class="col-span-full">
          <span>Meerdere lidmaatschappen</span>
          <select v-model="priority">
            <option value="all">Allemaal toerekenen</option>
            <option value="min">Goedkoopste toerekenen</option>
            <option value="max">Duurste toerekenen</option>
          </select>
        </label>
      </div>
    </fieldset>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, inject, reactive, watch } from 'vue'

  const emit = defineEmits(['update'])
  const props = defineProps<{ onlyIban?: boolean; reset: boolean }>()

  const authorizedGroups = inject<Group[]>('authorizedGroups')

  const tariffs = reactive<{ [key: string]: number }>({})
  const roleTariffs = ref<RoleTariff[]>([])
  const priority = ref<'min' | 'max' | 'all'>('min')

  const normalizedTariffs = computed(() => {
    const localTariffs = JSON.parse(
      JSON.stringify(roleTariffs.value),
    ) as RoleTariff[]

    localTariffs.sort((a, b) => {
      if (priority.value === 'min') {
        return tariffs[a.id] - tariffs[b.id]
      } else {
        return tariffs[b.id] - tariffs[a.id]
      }
    })

    const personIds = Array.from(
      new Set(roleTariffs.value.flatMap((r) => r.personIds)),
    )

    return localTariffs
      .map((role) => {
        const localPersonIds: string[] = []

        ;(role.personIds as string[]).forEach((id) => {
          const idx = personIds.indexOf(id)

          if (idx >= 0) {
            localPersonIds.push(id)

            if (priority.value !== 'all') {
              personIds.splice(idx, 1)
            }
          }
        })

        return {
          count: localPersonIds.length,
          id: role.id,
          title: role.title,
          personIds: localPersonIds,
          tariff: tariffs[role.id],
        }
      })
      .sort((a, b) => (a.title > b.title ? 1 : -1))
  })

  const groupId = ref('')

  const variables = computed(() => {
    return {
      groupId: groupId.value,
      tariffs: normalizedTariffs.value,
    }
  })

  const loadCounts = async (id: string | null) => {
    if (id) {
      const resp = await fetch(
        `/api/groups/${id}/counts?onlyIban=${props.onlyIban ? 1 : 0}`,
      )
      const data = (await resp.json()) as RoleTariff[]

      data.forEach((r) => (tariffs[r.id] = 0))
      roleTariffs.value = data
    } else {
      Object.keys(tariffs).forEach((key) => delete tariffs[key])
      roleTariffs.value = []
    }
  }

  watch(groupId, loadCounts)
  watch([tariffs, groupId, priority], () => emit('update', variables.value))

  watch(
    () => props.reset,
    (bool) => {
      if (bool) {
        groupId.value = ''
      }
    },
  )
</script>
