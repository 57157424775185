<template>
  <toggle-box :in-list="false" @set:collapsed="isCollapsed = $event">
    <template #title>
      <span class="flex items-center gap-1">
        <span>Filters</span>
        <exclamation-circle-icon
          v-if="filtersActive"
          class="w-4 cursor-pointer text-slate-700"
          @click.stop="emit('reset')"
        />
        <label
          v-if="!isCollapsed"
          @click.stop
          class="-m-1 ml-auto mr-1 flex cursor-pointer items-center gap-1 rounded-full bg-slate-200 py-1 pl-2 pr-3 text-xs"
          :class="{ '!bg-slate-500 text-white': showArchived }"
        >
          <input
            v-model="showArchived"
            type="checkbox"
            class="fixed opacity-0"
          />
          <span class="opacity-20" :class="{ '!opacity-100': showArchived }">
            <check-icon v-if="showArchived" class="w-4" />
            <x-mark-icon v-else class="w-4" />
          </span>
          <span class="opacity-50" :class="{ '!opacity-100': showArchived }"
            >Archief</span
          >
        </label>
      </span>
    </template>

    <fieldset class="grid gap-1 space-y-0 pb-1 sm:grid-cols-6 sm:gap-3">
      <label class="sm:col-span-2">
        <span>Zoekterm</span>
        <input v-model="filters.query" placeholder="Zoek op naam" />
      </label>

      <section class="grid gap-1 sm:col-span-4 sm:grid-cols-4 sm:gap-3">
        <label>
          <span>Woonplaats</span>
          <select v-model="filters.locality">
            <option value="">Alle</option>
            <option
              :value="locality"
              v-for="locality in localities"
              :key="locality"
            >
              {{ locality }}
            </option>
          </select>
        </label>

        <label>
          <span>Geslacht</span>
          <select v-model="filters.gender">
            <option value="">Alle</option>
            <option
              :value="idx"
              v-for="(title, idx) in genderTitles"
              :key="`gender-${idx}`"
            >
              {{ title }}
            </option>
          </select>
        </label>

        <label>
          <span>Groep</span>
          <select v-model="filters.groupId">
            <option value="">Alle</option>
            <option
              :value="group.id"
              v-for="group in authorizedGroups"
              :key="`group-filter-${group.id}`"
            >
              {{ group.name }}
            </option>
          </select>
        </label>

        <label v-if="tenant">
          <span>Lidmaatschap</span>
          <select v-model="filters.roleId">
            <option value="">Alle</option>
            <option
              :value="role.id"
              v-for="role in tenant.roles as Role[]"
              :key="role.id"
            >
              {{ role.title }}
            </option>
          </select>
        </label>
      </section>
    </fieldset>
  </toggle-box>
</template>

<script setup lang="ts">
  import { computed, inject, ref, watch } from 'vue'
  import {
    ExclamationCircleIcon,
    CheckIcon,
    XMarkIcon,
  } from '@heroicons/vue/20/solid'

  import ToggleBox from './ToggleBox.vue'

  const emit = defineEmits(['reset'])
  const props = defineProps<{ filters: PeopleFilters }>()

  const tenant = inject<Tenant>('tenant')
  const genderTitles = inject<string[]>('genderTitles')
  const authorizedGroups = inject<Group[]>('authorizedGroups')

  const isCollapsed = ref(true)
  const showArchived = ref(props.filters.archived === '1')
  const localities = ref<string[]>([])

  const filtersActive = computed(() => {
    return Object.values(props.filters).some((v) =>
      v === '0' ? false : new String(v).length,
    )
  })

  const loadFilters = async () => {
    const resp = await fetch('/api/settings/filters')
    const data = await resp.json()

    localities.value = data.localities
  }

  watch(showArchived, (bool) => {
    props.filters.archived = bool ? '1' : '0'
  })

  loadFilters()
</script>
