<template>
  <div class="space-y-4">
    <h1 class="flex items-center text-lg font-bold">
      <span class="truncate">Ledenlijst</span>

      <div class="ml-auto flex gap-2">
        <a
          :href="exportUrl"
          class="button bg-orange-700 hover:bg-orange-800"
          target="_blank"
        >
          <arrow-down-tray-icon class="w-4" />
          <span>Exporteer</span>
        </a>

        <router-link :to="{ name: 'NewPerson' }" class="button pl-2">
          <plus-icon class="w-4" />
          <span class="whitespace-nowrap">Nieuw lid</span>
        </router-link>
      </div>
    </h1>

    <div class="space-y-5">
      <people-filter :filters="filters" @reset="resetFilters" />
      <people-list :people="people" />
      <pagination v-if="hasNextPage" @next="nextPage" />

      <div v-if="isFetching" class="flex flex-col items-center py-1">
        <loading-view />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, onActivated, onMounted, reactive, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useHead } from '@vueuse/head'

  import { ArrowDownTrayIcon, PlusIcon } from '@heroicons/vue/20/solid'

  import PeopleList from '../components/PeopleList.vue'
  import PeopleFilter from '../components/PeopleFilter.vue'
  import LoadingView from '../components/LoadingView.vue'
  import Pagination from '../components/Pagination.vue'

  const isFetching = ref(false)
  const hasNextPage = ref(true)

  const exportUrl = computed(() => {
    return '/api/people?format=csv&' + new URLSearchParams(filters)
  })

  const page = ref(0)
  const perPage = parseInt(import.meta.env.VITE_PER_PAGE)

  const route = useRoute()
  const params = new URLSearchParams(document.location.search)

  const people = ref<Person[]>([])
  const filters = reactive<PeopleFilters>({
    query: '',
    locality: '',
    roleId: '',
    gender: '',
    groupId: (route.query.groupId as string) || '',
    archived: params.get('archived') === '1' ? '1' : '0',
  })

  onActivated(() => {
    if (route.query.groupId) {
      if (route.query.groupId !== filters.groupId) {
        filters.groupId = route.query.groupId as string
      }

      history.pushState(null, '', document.location.pathname)
    }
  })

  const loadTimeout = ref<number | null>(null)

  const loadPeople = async () => {
    isFetching.value = true

    const resp = await fetch(
      `/api/people?page=${page.value}&` + new URLSearchParams(filters),
    )
    const results = await resp.json()

    if (results.length < perPage) {
      hasNextPage.value = false
    }

    people.value = people.value.concat(results)
    isFetching.value = false
  }

  const nextPage = () => {
    page.value += 1
    loadPeople()
  }

  const resetFilters = () => {
    Object.assign(filters, {
      query: '',
      locality: '',
      roleId: '',
      gender: '',
      groupId: '',
    })
  }

  watch(filters, () => {
    hasNextPage.value = true

    if (loadTimeout.value) {
      window.clearTimeout(loadTimeout.value)
    }

    loadTimeout.value = window.setTimeout(() => {
      page.value = 0
      people.value = []
      loadPeople()
    }, 350)
  })

  onMounted(loadPeople)
  useHead({ title: 'Ledenlijst' })
</script>
